import {ref} from 'vue';
import localStorageHelper from 'o365.modules.StorageHelpers.ts';

const tabs = ref([]);
const current = ref(null);
const storageKey:string= location.pathname+"/"+"TabControl";

export default class TabControl{
    private _dataObject:any;
    prev:number = null;
    onTabClose:Function;
    onTabOpen:Function;


    get currentTab(){
        return current.value;
    }


    get tabs(){
        return tabs.value;
    }

    constructor(pOptions:any = {}){
        if(pOptions.hasOwnProperty("dataObject")){
            this._dataObject = pOptions.dataObject;
        }
        if(pOptions.hasOwnProperty("onTabClose")){
            this.onTabClose = pOptions.onTabClose;
        }
        if(pOptions.hasOwnProperty("onTabOpen")){
            this.onTabOpen = pOptions.onTabOpen;
        }

        if(this._dataObject){
            //init all what we neeed
        }

        let vRes = localStorageHelper.getItem(storageKey);
        if(vRes){
            
            JSON.parse(vRes).forEach((tab: Tab)=>{
                this.openTab(tab);
            });
        }

    }
    openTab(pItem:Tab){
        
        if(!pItem.id) console.error("id is required for tabs");



        let tabIndex = this.getTabIndex(pItem);
        this._closeInactiveTabs();
        
        if(tabIndex > -1 ){
            this.setActiveIndex(tabIndex);
            return;
        }

        this.resetActive();

       // this._closeInactiveTabs();
        pItem.order = this.tabs.length;
        this.tabs.push(new Tab(pItem));
        current.value = this.tabs[this.tabs.length - 1];
    //    location.hash = current.value.href;
        if(this.onTabOpen) this.onTabOpen.call(this,this.tabs[this.tabs.length - 1]);
        saveToLocalStorage();
    }

    resetActive(){
        this.tabs.forEach((tab:Tab,index:number)=>{
            this.tabs[index].current = false;
        })
       
    }

    getTabIndex(pItem:Tab){
        return this.tabs.findIndex((x: { id: string; })=>x.id===pItem.id);
    }

    setActive(pItem:Tab){
        const vIndex = this.getTabIndex(pItem);
        this.setActiveIndex(vIndex);
        
        
    }

    setActiveIndex(pIndex:number, pSkipFncFiring:Boolean = false){
        this.resetActive();
        if(!this.tabs[pIndex]) return;
        this.tabs[pIndex].current = true;
        current.value = this.tabs[pIndex];
     //   location.hash = current.value.href;
        if(this.onTabOpen && !pSkipFncFiring) this.onTabOpen.call(this,this.tabs[pIndex]);
       saveToLocalStorage();
        
    }
    closeTab(pItem:Tab){
        const vTabIndex = this.getTabIndex(pItem);
        this.closeTabByIndex(vTabIndex);
        this._setOrder();
       
        if(this.tabs.length >0 ){
            this.setActiveIndex(0,true);
        }
        if(this.onTabClose){
            this.onTabClose.call(this,pItem);
        }
       
    }

    closeTabByIndex(pIndex:number){
        tabs.value.splice(pIndex,1);
    }

    private _sort(){
        this.tabs.sort((a:Tab,b:Tab)=>a.order-b.order);
    }

    private _setOrder(){
        this.tabs.forEach((tab: Tab,index: number)=>{
            tab.order = index;
        })

        this._sort();
    }

    private _closeInactiveTabs(){
        const vInactive = this.tabs.filter((x: { pinned: any; })=>!x.pinned);
        vInactive.forEach((tab: Tab)=>{
            this.closeTab(tab);
        });

    }

}

class Tab{
    item:any;
    private _pinned:boolean=false;
    private _current:boolean=true;
   
    href:string;
    id:string;
    order:number;
    hasChanges:boolean = false;
    saveItem:boolean = false;

    get pinned(){
        return this._pinned;
    }
    set pinned(pState){
        this._pinned = pState;
        saveToLocalStorage();
    }
    get current(){
        return this._current;
    }
    set current(pState){
        this._current = pState;
        saveToLocalStorage();
    }




    constructor(pOptions:Tab){
        this.item = pOptions.item;
        this.id = pOptions.id;
        this.href = pOptions.href;
        if(pOptions.current) this.current = pOptions.current;
        if(pOptions.pinned) this._pinned = pOptions.pinned;
        if(pOptions.saveItem) this.saveItem = pOptions.saveItem;
    }
}
function saveToLocalStorage(){
        const toSave = [];
        tabs.value.filter((x:Tab)=>x.pinned).forEach((tab:Tab)=>{
            toSave.push({
                id:tab.id,
                href:tab.href,
                pinned:tab.pinned,
                current:tab.current,
                item:tab.saveItem?tab.item:{},
                saveItem:tab.saveItem
            })
        });
        localStorageHelper.setItem(storageKey,JSON.stringify(toSave));
    }



export {tabs};